.pageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    flex: 1;
}

.colRowNumberCell {

}