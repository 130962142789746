@page {
    size: A4;
}

.printFile {
    width: 210mm;
    max-width: 210mm;
    background-color: #FFFFFF;
}

.pageContent {
    width: 210mm;
    height: 297mm;
    display: flex;
    flex-direction: column;
    align-items: center;
}