/* ImageUpload.module.css */

.cropContainer {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.variationsContainer {
    display: flex;
    overflow-x: auto;
    /* Additional styles for variations container */
}

.variationImage {
    margin: 0 10px;
    cursor: pointer;
    border: 4px solid #9b9b9b; /* Default border */
    border-radius: 5px;
    width: 100px;
    height: 100px;
    image-rendering: pixelated;
    /* Additional styles for variation images */
}

.selectedVariation {
    border: 4px solid #e91e63; /* Style for selected variation */
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Adjust as needed */
    margin-top: 20px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid blue; /* Spinner color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

.submitButton:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

/* ImageUpload.module.css */

.pageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    flex: 1;
}

.imagePreview {
    border: 2px solid #FFF; /* White border */
    margin: 20px 0;
}

.button {
    background-color: #FFFFFF; /* White button */
    border: none;
    border-radius: 50%; /* Circular button */
    width: 50px; /* Example size */
    height: 50px; /* Example size */
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigationButton {
    background-color: #000000; /* Black button */
    color: #FFFFFF; /* White text */
    border: none;
    border-radius: 20px; /* Slightly rounded corners for a pill shape */
    padding: 10px 20px;
    margin-top: 20px;
}

.controlsContainer {

}

.controlButton {

}

.continueButton {

}

.initialStateContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Adjust as needed */
    margin-top: 20px;
}

.button {
    height: 30px;
    min-width: 70px;
}

.iconButton {

}


.uploadButton {
    height: 30px;
}

.flexibleContainerWithMargin {
    display: flex!important;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
}

.flexibleHorizontalContainerWithMargin {
    display: flex!important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.fullSizeContainer {
    flex: 1;
}
.initialActionContainer {

}
/* Add additional styles based on your specific design requirements */
