.pageContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    flex: 1;
}

.pictureContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.examplePicture {
    width: 100%;
    max-width: 200px;
    border-radius: 5px;
}

.flexibleContainerWithMargin {
    display: flex!important;
    justify-content: center;
    margin-bottom: 20px;
}